/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

const sidebar_collapse_key = "sidebar_collapse";

require("html2canvas");

window.addEventListener("load", (event) => {
    if (typeof loadApprovedEventsForCurrentYearChart === "function") {
        loadApprovedEventsForCurrentYearChart();
    }

    if (typeof loadVolunteerAgeChart === "function") {
        loadVolunteerAgeChart();
    }

    if (typeof loadEventCausesChart === "function") {
        loadEventCausesChart();
    }

    if (typeof loadVolunteerGenderChart === "function") {
        loadVolunteerGenderChart();
    }

    if (typeof loadVolunteerRaceChart === "function") {
        loadVolunteerRaceChart();
    }

    if (typeof loadVolunteerProfessionChart === "function") {
        loadVolunteerProfessionChart();
    }

    if (typeof loadStudentInstitutionChart === "function") {
        loadStudentInstitutionChart();
    }

    if (typeof loadEventReportChart === "function") {
        loadEventReportChart();
    }

    if (typeof loadCausesChart === "function") {
        loadCausesChart();
    }

    if (typeof loadMonthlyVolunteeringReportChart === "function") {
        loadMonthlyVolunteeringReportChart();
    }

    if (typeof loadDailyVolunteeringReportChart === "function") {
        loadDailyVolunteeringReportChart();
    }

    if (typeof loadApprovedOrganisationChart === "function") {
        loadApprovedOrganisationChart();
    }

    if (typeof loadOrganisationClassificationChart === "function") {
        loadOrganisationClassificationChart();
    }

    if (typeof loadVolunteerClassificationChart === "function") {
        loadVolunteerClassificationChart();
    }

    if (typeof loadOrganisationSectorChart === "function") {
        loadOrganisationSectorChart();
    }

    if (typeof loadOrganizationRegistrationChart === "function") {
        loadOrganizationRegistrationChart();
    }

    if (typeof loadVolunteerDistrictChart === "function") {
        loadVolunteerDistrictChart();
    }

    if (typeof loadRedeemedItemsReportChart === "function") {
        loadRedeemedItemsReportChart();
    }

    if (typeof loadRedeemedVouchersReportChart === "function") {
        loadRedeemedVouchersReportChart();
    }

    if (typeof loadVolunteerRegistrationKPIChart === "function") {
        loadVolunteerRegistrationKPIChart();
    }

    if (typeof loadOrganisationRegistrationKPIChart === "function") {
        loadOrganisationRegistrationKPIChart();
    }

    if (typeof loadProgramImplementationKPIChart === "function") {
        loadProgramImplementationKPIChart();
    }

    if (typeof loadTotalDownloadChart === "function") {
        loadTotalDownloadChart();
    }

    if (typeof loadTotalAppStoreDownloadChart === "function") {
        loadTotalAppStoreDownloadChart();
    }

    if (typeof loadTotalGooglePlayDownloadChart === "function") {
        loadTotalGooglePlayDownloadChart();
    }

    if (typeof loadTotalProgramImplementedChart === "function") {
        loadTotalProgramImplementedChart();
    }

    if (typeof loadTotalDownloadComparisonChart === "function") {
        loadTotalDownloadComparisonChart();
    }

    if (typeof loadTotalIndividualRegistrationChart === "function") {
        loadTotalIndividualRegistrationChart();
    }

    if (typeof loadTotalRegisteredOrganisationChart === "function") {
        loadTotalRegisteredOrganisationChart();
    }

    if (typeof loadTotalOrganisationSectorChart === "function") {
        loadTotalOrganisationSectorChart();
    }

    if (typeof loadTotalProgramRegisteredImplementedChart === "function") {
        loadTotalProgramRegisteredImplementedChart();
    }

    if (typeof loadTotalBreakdownProgramClusterChart === "function") {
        loadTotalBreakdownProgramClusterChart();
    }

    if (typeof loadBreakdownTotalAppDownloadChart === "function") {
        loadBreakdownTotalAppDownloadChart();
    }

    if (typeof loadTotalAppDownloadChart === "function") {
        loadTotalAppDownloadChart();
    }

    if (typeof loadBreakdownVolunteerGenderChart === "function") {
        loadBreakdownVolunteerGenderChart();
    }

    if (typeof loadVolunteerCitizenshipChart === "function") {
        loadVolunteerCitizenshipChart();
    }

    if (typeof loadBreakdownVolunteerCitizenshipChart === "function") {
        loadBreakdownVolunteerCitizenshipChart();
    }

    if (typeof loadBreakdownVolunteerRaceChart === "function") {
        loadBreakdownVolunteerRaceChart();
    }

    if (typeof loadnumberVolunteerRaceChart === "function") {
        loadnumberVolunteerRaceChart();
    }

    if (typeof loadBreakdownRegisteredVolunteerDsitrictChart === "function") {
        loadBreakdownRegisteredVolunteerDsitrictChart();
    }

    if (typeof loadBreakdownTotalVolunteerYouthAgeChart === "function") {
        loadBreakdownTotalVolunteerYouthAgeChart();
    }

    if (typeof loadTotalVolunteerAgeDefinitionGenderChart === "function") {
        loadTotalVolunteerAgeDefinitionGenderChart();
    }

    if (typeof loadBreakdownVolunteerProfessionChart === "function") {
        loadBreakdownVolunteerProfessionChart();
    }

    if (typeof loadBreakdownVolunteerStudentCategoryChart === "function") {
        loadBreakdownVolunteerStudentCategoryChart();
    }

    if (typeof loadBreakdownOrganizationBySectorChart === "function") {
        loadBreakdownOrganizationBySectorChart();
    }

    if (typeof loadBreakdownOrganizationRegistrationMonthChart === "function") {
        loadBreakdownOrganizationRegistrationMonthChart();
    }

    if (
        typeof loadBreakdownTotalProgramRegisteredImplementedChart ===
        "function"
    ) {
        loadBreakdownTotalProgramRegisteredImplementedChart();
    }

    if (typeof loadOrganizationRegistrationBreakdownChart === "function") {
        loadOrganizationRegistrationBreakdownChart();
    }

    if (typeof loadOrganizationRegistrationBreakdownChart2 === "function") {
        loadOrganizationRegistrationBreakdownChart2();
    }

    if (typeof loadEventNotImplementedChart === "function") {
        loadEventNotImplementedChart();
    }

    setTimeout(() => {
        const alerts = document.getElementsByClassName("alert");

        for (let i = 0; i < alerts.length; i++) {
            alerts[i].remove();
        }
    }, 3000);
});

window.addEventListener("alert-error", (event) => {
    const alertError = document.getElementById("js-error-alert");
    alertError.classList.remove("hidden");

    setTimeout(() => {
        alertError.classList.add("hidden");
    }, 3000);
});

const menu_button = document.getElementById("menu_button");
const mobile_close_button = document.getElementById("mobile_close_button");

menu_button.addEventListener("click", function () {
    Livewire.emit("menuButtonClicked");
    updateSidebar();
});

mobile_close_button.addEventListener("click", function () {
    Livewire.emit("menuButtonClicked");
    updateSidebar();
});

// get the elements of the nav items with children
const collapsible_nav_items = document.getElementsByClassName(
    "collapsible_nav_item"
);

// when the nav item is clicked, emit livewire event navItemClicked which will be handled by Sidebar.php
for (let i = 0; i < collapsible_nav_items.length; i++) {
    collapsible_nav_items[i].addEventListener("click", function () {
        Livewire.emit("navItemClicked");
        updateSidebar("nav_item");
    });
}

// if item_type is "nav_item" then no need to uncollapse the collapsed sidebar
// we just want to collapse the sidebar, if the sidebar is not collapsed and the nav item is clicked
// just to show the children component
function updateSidebar(item_type = undefined) {
    const sidebar_container = document.getElementById("sidebar_container");
    const main_container = document.getElementById("main_container");
    // const header_container = document.getElementById("header_container");

    const nav_item_names = document.getElementsByClassName("nav_item_names");
    const version_footer = document.getElementById("version_footer");

    // if the sidebar container has the class of col-span-3, it means that the sidebar is collapsed
    // otherwise the sidebar is not collapsed

    if (
        item_type === "nav_item" &&
        sidebar_container.classList.contains("lg:w-2/12") &&
        sidebar_container.classList.contains("sidebar_collapse")
    )
        return;

    if (
        sidebar_container.classList.contains("lg:w-1/12") &&
        sidebar_container.classList.contains("sidebar_not_collapse")
    ) {
        sidebar_container.classList.remove("sidebar_not_collapse");
        sidebar_container.classList.remove("lg:w-1/12");
        sidebar_container.classList.remove("w-0");
        sidebar_container.classList.remove("hidden");
        sidebar_container.classList.add("sidebar_collapse");
        sidebar_container.classList.add("lg:w-2/12");
        sidebar_container.classList.add("w-full");

        main_container.classList.remove("lg:w-11/12");
        main_container.classList.remove("w-full");

        main_container.classList.add("lg:w-10/12");
        main_container.classList.add("w-0");

        version_footer.classList.remove("hidden");

        // show the wordings
        for (let i = 0; i < nav_item_names.length; i++) {
            nav_item_names[i].classList.remove("hidden");
        }
    } else {
        sidebar_container.classList.remove("sidebar_collapse");
        sidebar_container.classList.remove("lg:w-2/12");
        sidebar_container.classList.remove("w-full");
        sidebar_container.classList.add("sidebar_not_collapse");
        sidebar_container.classList.add("lg:w-1/12");
        sidebar_container.classList.add("w-0");

        main_container.classList.add("lg:w-11/12");
        main_container.classList.add("w-full");

        main_container.classList.remove("lg:w-10/12");
        main_container.classList.remove("w-0");

        version_footer.classList.add("hidden");

        // hide the wording
        for (let i = 0; i < nav_item_names.length; i++) {
            nav_item_names[i].classList.add("hidden");
        }
    }
}
